import React, { useState, useRef, useEffect } from "react";
import { Link } from "gatsby";
import Logo from "./partials/Logo";
import list from "../listOfLinks";
import classNames from "classnames";

const Nav = ({ className }) => {
	const [isScrolled, setIsScrolled] = useState(false);

	function scrollIt() {
		window.pageYOffset > 100 ? setIsScrolled(true) : setIsScrolled(false);
	}

	useEffect(() => {
		function watchScroll() {
			window.addEventListener("scroll", scrollIt);
		}
		watchScroll();
		return () => {
			window.removeEventListener("scroll", scrollIt);
		};
	}, []);

	const [isActive, setIsactive] = useState(false);

	const nav = useRef(null);
	const hamburger = useRef(null);

	useEffect(() => {
		isActive && openMenu();
		document.addEventListener("keydown", keyPress);
		document.addEventListener("click", clickOutside);
		return () => {
			document.removeEventListener("keydown", keyPress);
			document.removeEventListener("click", clickOutside);
			closeMenu();
		};
	});

	const openMenu = () => {
		document.body.classList.add("off-nav-is-active");
		nav.current.style.maxHeight = nav.current.scrollHeight + "px";
		setIsactive(true);
	};

	const closeMenu = () => {
		document.body.classList.remove("off-nav-is-active");
		nav.current && (nav.current.style.maxHeight = null);
		setIsactive(false);
	};

	const keyPress = (e) => {
		isActive && e.keyCode === 27 && closeMenu();
	};

	const clickOutside = (e) => {
		if (!nav.current) return;
		if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
		closeMenu();
	};

	return (
		<nav className={classNames("navbar", className, isScrolled && "navbar--scrolled")}>
			<div className="container">
				<div className="navbar__inner">
					<Logo />
					<>
						<button ref={hamburger} className="navbar__inner--toggle" onClick={isActive ? closeMenu : openMenu}>
							<span className="screen-reader">Menu</span>
							<span className="hamburger">
								<span className="hamburger__inner"></span>
							</span>
						</button>
						<div ref={nav} className={classNames("navbar__header", isActive && "is-active")}>
							<div className="navbar__header__inner">
								<ul className="navbar__header__inner--left list-reset">
									{list.map((link) => (
										<li key={link[0]}>
											<Link to={link[0]} onClick={closeMenu}>
												{link[1]}
											</Link>
										</li>
									))}
								</ul>
								<ul className="navbar__header__inner--right list-reset">
									<li>
										<a href="mailto:hello@orangejellyfish.com" target="_blank" rel="noreferrer" className="button button-primary button-wide-mobile" onClick={closeMenu}>
											Get in touch
										</a>
									</li>
								</ul>
							</div>
						</div>
					</>
				</div>
			</div>
		</nav>
	);
};

export default Nav;
