const list = [
    ["/", "Home"],
    ["/team/", "Team"],
    ["/work/", "Work"],
    ["/startup/", "Start Up"],
    ["/blog/", "Blog"],
    ["/jobs/", "Jobs"]
]

export default list;
