import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import Image from "../../elements/Image";

// Images
import logo from "../../../assets/images/logo-orangejellyfish.svg";

const Logo = ({ className }) => {
	return (
		<div className={classNames("brand", className)}>
			<Link to="/">
				<Image src={logo} alt="orangejellyfish" width={64} height={64} />
			</Link>
		</div>
	);
};

export default Logo;
