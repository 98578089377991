import React from "react";
import {
	AiOutlineInstagram,
	AiOutlineLinkedin,
	AiOutlineTwitter,
 } from 'react-icons/ai';

const Cta = () => {
	return (
		<section className="section-cta">
				<div className="container">
					<div className="section-cta__inner">
						<div className="section-cta__plus section-cta__plus--left"></div>
						<div className="section-cta__inner--slogan">
							<div className="section-cta__inner--left">
								<h4>Want to know more?</h4>
								<div className="section-cta__icons">
									<a
										href="https://twitter.com/orangejellyhq"
										target="_blank"
										className="section-cta__icons--icon"
									>
										<AiOutlineTwitter />
									</a>
									<a
										href="https://www.linkedin.com/company/orangejellyfish"
										target="_blank"
										className="section-cta__icons--icon"
									>
										<AiOutlineLinkedin />
									</a>
									<a
										href="https://www.instagram.com/theorangejellyfish/"
										target="_blank"
										className="section-cta__icons--icon"
									>
										<AiOutlineInstagram />
									</a>
								</div>
							</div>
							<a href="mailto:hello@orangejellyfish.com">
								<h2>Get in touch.</h2>
							</a>
						</div>
						<div className="section-cta__plus section-cta__plus--right"></div>
					</div>
				</div>
		</section>
	);
};

export default Cta;
