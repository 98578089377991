import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import list from "../../listOfLinks";

const FooterNav = ({ className }) => {
	return (
		<div className={classNames("footer__nav", className)}>
			<ul className="list-reset">
				{list.map((link) => (
					<li key={link[0]}>
						<Link to={link[0]}>
							{link[1]}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default FooterNav;
