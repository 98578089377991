import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../components/layout/Nav";
import Footer from "../components/layout/Footer";
import metaImg from "../assets/images/meta-image.png";
import favicon from "../assets/images/favicon.png";

import "prismjs/themes/prism.css";
import "../assets/scss/style.scss";

const LayoutDefault = ({ children }) => (
	<>
		<Helmet>
			<title>Orange Jellyfish</title>
			<link rel="icon" href={favicon} /> 
			<meta name="description" content="We are experts in software development and delivery. We navigate digital projects with passion, expertise and ingenuity. We are Orange Jellyfish." />
			<meta property="og:title" content="Orange Jellyfish" />
			<meta property="og:description" content="We are experts in software development and delivery. We navigate digital projects with passion, expertise and ingenuity. We are Orange Jellyfish." />
			<meta property="og:image" image={metaImg} />
			<meta property="og:url" content="https://www.orangejellyfish.com/" />
			<meta name="twitter:card" image={metaImg} />
		</Helmet>
		<Nav />
		<main className="site-content">{children}</main>
		<Footer />
	</>
);

export default LayoutDefault;
