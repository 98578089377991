import React from "react";
import Logo from "./partials/Logo";
import FooterNav from "./partials/FooterNav";

const Footer = () => {
	return (
		<footer className="footer">
			<div className="container">
				<div className="footer__inner">
					<div className="footer__inner--left">
						<Logo />
						<div className="footer__copyright">
							Copyright © 2022 orangejellyfish ltd.
							<br />
							Company registered in England & Wales, number 10878680.
						</div>
					</div>
					<div className="footer__inner--right">
						<FooterNav />
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
